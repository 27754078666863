require('normalize.css/normalize.css');
require('./styles/index.scss');

fadeInPage();

function fadeInPage() {
    if (!window.AnimationEvent) {
        return;
    }
    var fader = document.getElementById('fader');
    fader.classList.add('fade-out');
}

document.addEventListener('DOMContentLoaded', function () {
    if (!window.AnimationEvent) {
        return;
    }
    var anchors = document.getElementsByTagName('a');
    for (var idx = 0; idx < anchors.length; idx += 1) {
        if (anchors[idx].hostname !== window.location.hostname) {
            continue;
        }
        anchors[idx].addEventListener('click', function (event) {
            var fader = document.getElementById('fader'),
                anchor = event.currentTarget;

            var listener = function () {
                window.location = anchor.href;
                fader.removeEventListener('animationend', listener);
            };
            fader.addEventListener('animationend', listener);

            event.preventDefault();
            fader.classList.add('fade-in');
        });
    }
});


window.addEventListener('pageshow', function (event) {
    if (!event.persisted) {
      return;
    }
    var fader = document.getElementById('fader');
    fader.classList.remove('fade-in');
  });

document.addEventListener("DOMContentLoaded", () => {
    const navigation = document.getElementsByClassName("navigation")[0];

    const homeTitle = createNode("h4");
    const homeLink = createNode("a");
    homeLink.href = "./index.html";
    homeLink.innerHTML = "Mekong Lam";
    append(homeTitle, homeLink);
    append(navigation, homeTitle);

    const workTitle = createNode("h4");
    const workLink = createNode("a");
    workLink.href = "./work.html";
    workLink.innerHTML = "Work";
    append(workTitle, workLink);
    append(navigation, workTitle);
    /*
        const blurbTitle = createNode("h4");
        const blurbLink = createNode("a");
        blurbLink.href = "./blurb.html";
        blurbLink.innerHTML = "Blurb";
        append(blurbTitle, blurbLink);
        append(navigation, blurbTitle);
    */
    const aboutTitle = createNode("h4");
    const aboutLink = createNode("a");
    aboutLink.href = "./about.html";
    aboutLink.innerHTML = "About";
    append(aboutTitle, aboutLink);
    append(navigation, aboutTitle);

    function createNode(element) {
        return document.createElement(element); // Create the type of element you pass in the parameters
    }

    function append(parent, el) {
        return parent.appendChild(el); // Append the second parameter (element) to the first one
    }

});